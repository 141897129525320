import deepmerge from 'core/libs/deepmerge';
import color from 'core/libs/color';

import Logo from 'site/icons/Logo';
import Arrow from 'site/icons/Arrow';
import ArrowLeft from 'site/icons/ArrowLeft';
import ArrowRight from 'site/icons/ArrowRight';
import Burger from 'site/icons/Burger';
import Close from 'site/icons/Close';
import CloseWhite from 'site/icons/CloseWhite';

import fonts from './fonts';

import { CONTENT_AREA, SIDE_INDENT } from 'site/constants';


const icons = {
  logo: Logo,
  arrowLeft: ArrowLeft,
  arrowRight: ArrowRight,
  burger: Burger,
  close: Close,
};

export default function baseTheme(atoms) {
  const pages = {
    offline: {
      background: 'transparent',
    },
  };

  const animations = {
    hover: '150ms ease-out',
  };

  const layout = {
    maxWidth: '1280px',
    boxShadow: 'none',
    contentColumnMaxWidth: CONTENT_AREA + 'px',
    sideColumnIndent: '144px',
    rightColumnWidth: '240px',
    indents: {
      top: 50,
      right: SIDE_INDENT,
      bottom: 125,
      left: SIDE_INDENT,
    },
  };

  const texts = {
    lead: {
      color: atoms.colors.gray1000,
      font: `500 20px/30px ${fonts.text}`,
    },

    body: {
      font: `500 18px/27px ${fonts.text}`,
    },

    title1: {
      font: `700 40px/46px ${fonts.display}`,
    },

    title2: {
      font: `700 32px/37px ${fonts.display}`,
    },

    title3: {
      font: `700 24px/26px ${fonts.display}`,
    },

    title4: {
      font: `700 20px/23px ${fonts.display}`,
    },
  };

  const link = {
    article: {
      idle: {
        colors: atoms.colors.active1,
        decoration: 'underline',
      },
      hover: {
        colors: atoms.colors.active2,
        decoration: 'none',
      },
      active: {
        colors: atoms.colors.active2,
        decoration: 'none',
      },
      visited: {
        color: false,
        decoration: false,
      },
    },

    primary: {
      idle: {
        color: atoms.colors.primary,
        decoration: 'none',
      },
      hover: {
        color: atoms.colors.primary,
        decoration: 'underline',
      },
      active: {
        color: atoms.colors.primary,
        decoration: 'none',
      },
      visited: {
        color: false,
        decoration: false,
      },
    },

    tag: {
      idle: {
        color: atoms.colors.placeholder,
        decoration: 'underline',
      },
      hover: {
        color: atoms.colors.placeholder,
        decoration: 'none',
      },
      active: {
        color: atoms.colors.placeholder,
        decoration: 'none',
      },
      visited: {
        color: false,
        decoration: false,
      },
    },
  };

  const buttonSizes = {
    small: {
      font: `600 14px/17px ${fonts.text}`,
      width: '93px',
      height: '18px',
      padding: '9px 15px 11px',
    },

    medium: {
      font: `600 14px/17px ${fonts.text}`,
      width: '170px',
      height: '18px',
      padding: '20px 55px',
    },
  };

  const button = {
    const: {
      sizes: buttonSizes,
      radius: '40px',
    },

    tertiary: {
      idle: {
        color: atoms.colors.content,
        background: atoms.colors.active1,
        border: 'none',
      },

      hover: {
        color: atoms.colors.content,
        background: color(atoms.colors.active1).alpha(0.9).string(),
        border: 'none',
      },

      active: {
        color: atoms.colors.content,
        background: color(atoms.colors.active1).alpha(0.9).string(),
        border: 'none',
      },

      disabled: {
        color: atoms.colors.content,
        background: atoms.colors.active1,
        border: 'none',
      },
    },
  };

  const SHAPKA_LINK_SIDE_INDENT = 15;

  const shapka = {
    sticky: false,
    background: 'transparent',

    link: {
      font: `600 16px/26px ${fonts.text}`,
      padding: `0 ${SHAPKA_LINK_SIDE_INDENT}px`,
    },

    logo: {
      width: 310,
      height: 112,
    },

    menu: {
      right: true,
    },

    height: 150,
    padding: `40px ${SIDE_INDENT - SHAPKA_LINK_SIDE_INDENT}px 0 ${SIDE_INDENT}px`,
  };

  const paginatron = {
    btnSize: 'small',
    separationText: '/',
    round: false,
    withOuterButtons: false,
    btnType: 'primary',
    withoutBorder: true,
    NextPage: () => 'Вперёд',
    PrevPage: () => 'Назад',
    text: {
      font: `500 18px/21px ${fonts.text}`,
      color: atoms.colors.primary,
    },
    radius: '40px',
    justifyContent: 'center',
    countersMargin: '0 1em',
  };

  const topicHeaderTexts = {
    subtitle: {
      font: `500 30px/40px ${fonts.text}`,
    },
    lead: {
      top: 20,
    },
    marginBottom: 30,
  };

  const topicContent = {
    widgetsMaxWidth: CONTENT_AREA,

    widgetsWithNegativeMobileIndent: {
      readMore: false,
    },

    widget: {
      top: 20,
      bottom: 20,
    },

    image: {
      bottom: 20,
    },

    list: {
      top: 20,
      bottom: 20,
    },
  };

  const captionCredits = {
    textAlign: 'left',
    caption: {
      font: `500 13px/18px ${fonts.text}`,
      color: atoms.colors.caption,
    },
    credits: {
      font: `500 12px/18px ${fonts.text}`,
      color: atoms.colors.credits,
      margin: '5px 0 0',
    },
  };

  const vikontSpoiler = {
    icons: {
      arrowRegularSharp: Arrow,
    },
    title: {
      font: `700 18px/27px ${fonts.text}`,
    },
    block: {
      outerIndent: '20px 0',
      innerIndent: '20px',
      background: atoms.colors.babyBlossom,
      borderRadius: '6px',
      unbordered: true,
    },
    body: {
      font: `500 16px/24px ${fonts.text}`,
      padding: '0 20px 20px',
    },
  };

  const gallery = {
    icons: {
      Close: CloseWhite,
    },
    thumb: {
      reloadIconColor: atoms.colors.active2,
      borderRadius: 0,
      idle: {
        fade: 'none',
        borderColor: 'transparent',
      },

      hover: {
        fade: 'none',
        borderColor: atoms.colors.active1,
      },

      active: {
        fade: 'none',
        borderColor: atoms.colors.active2,
      },
    },

    slide: {
      background: 'transparent',
      borderRadius: 6,
    },

    arrows: {
      idle: {
        fill: atoms.colors.content,
        stroke: '#EAEAEA',
      },
      idleFullscreen: {
        fill: atoms.colors.content,
        stroke: '#EAEAEA',
      },
      hover: {
        fill: atoms.colors.active2,
        stroke: atoms.colors.active2,
      },
      hoverFullscreen: {
        fill: atoms.colors.active2,
        stroke: atoms.colors.active2,
      },
    },

    fullscreen: {
      background: atoms.colors.primary,
    },
  };

  const counter = {
    font: `500 14px/20px ${fonts.text}`,
  };

  const vikontReadMore = {
    block: {
      padding: 0,
    },
    drum: {
      sideIndent: 0,
    },
  };

  return deepmerge({
    fonts,
    icons,
    texts,
    pages,
    layout,
    animations,
    controls: {
      shapka,
      button,
      link,
      topicHeaderTexts,
      topicContent,
      captionCredits,
      vikontSpoiler,
      vikontReadMore,
      gallery,
      counter,
      paginatron,
    },
  }, atoms);
}
