import PropTypes from 'prop-types';

import { processQueryResults } from 'core/utils/query';
import useRequest from 'core/hooks/useRequest';
import { topicsQuery } from 'core/queries/topics';

import { withBreakpoint } from 'core/components/breakpoint';

import { filterRequiredParams } from 'core/utils/api';

import Page from 'core/components/Page';
import Link from 'core/components/Link';
import Position from 'core/components/GridPosition';
import { PageIndent, Indent } from 'core/components/Wrappers';
import FullWidth from 'core/components/FullWidth';
import PageLoader from 'core/components/Loader/PageLoader';

import Wave from 'site/components/Wave';
import ButtonBubbles from 'site/components/ButtonBubbles';

import CardSimple from 'site/cards/CardSimple';
import { CardMainM } from 'site/cards/CardMain';
import { CardVerticalM } from 'site/cards/CardVertical';
import CardQuiz from 'site/cards/CardQuiz';

import News from './News';
import OnePlusTwo from './OnePlusTwo';
import MoreTopics from './MoreTopics';

import {
  VERTICAL_INDENT,
  VERTICAL_INDENT_MOBILE,
} from 'site/constants';

import styles from './index.styl';


function MainPage(props) {
  const {
    isMobile,
  } = props;

  const {
    data: [
      news,
      articles,
      quizes,
    ],
    isLoading,
  } = processQueryResults(useRequest({
    queries: [
      topicsQuery({
        limit: 11,
        topic_type: 'news',
        include: filterRequiredParams([CardVerticalM], 'include'),
        fields: filterRequiredParams([CardSimple, CardVerticalM], 'fields'),
        renderError: () => [],
      }),
      topicsQuery({
        limit: 3,
        topic_type: 'article',
        include: filterRequiredParams([CardVerticalM, CardMainM], 'include'),
        fields: filterRequiredParams([CardVerticalM, CardMainM], 'fields'),
        renderError: () => [],
      }),
      topicsQuery({
        limit: 1,
        rubric: 'tests',
        include: filterRequiredParams([CardQuiz], 'include'),
        fields: filterRequiredParams([CardQuiz], 'fields'),
        renderError: () => [],
      }),
    ],
  }));

  if (isLoading) return <PageLoader />;

  const newsFirst = news.slice(0, 6);
  const newsSecond = news.slice(6);
  const vertical = isMobile ? VERTICAL_INDENT_MOBILE : VERTICAL_INDENT;

  return (
    <Page
      title='Всемирный фестиваль молодёжи в 2024 в Сочи'
      description='Последние новости о Всемирном фестивале молодёжи в 2024 в Сочи'
    >
      <PageIndent bottom={vertical}>
        {newsFirst.length > 0 && <News content={newsFirst} />}
        <Indent top={isMobile ? 60 : VERTICAL_INDENT} />
        {(articles?.length > 0 || newsSecond?.length > 0 || quizes?.length > 0) && (
          <FullWidth className={styles.fullWidthContent}>
            <div className={styles.layoutWrapper}>
              <div className={styles.topicsWrapper}>
                {articles.length > 0 && (
                  <>
                    <OnePlusTwo content={articles} />
                    <Indent top={vertical} />
                    <div className={styles.waveWrapper}>
                      <Wave {...isMobile && { width: 70, strokeWidth: 10 }} />
                    </div>
                    <Indent top={vertical} />
                  </>
                )}
                {(quizes?.length > 0 || newsSecond?.length > 0) && (
                  <MoreTopics quiz={quizes[0]} news={newsSecond} />
                )}
              </div>
            </div>
            <Indent top={vertical} />
            <Position center>
              <Link type='primary' to='/articles'>
                <ButtonBubbles>Еще материалы</ButtonBubbles>
              </Link>
            </Position>
          </FullWidth>
        )}
      </PageIndent>
    </Page>
  );
}

MainPage.propTypes = {
  isMobile: PropTypes.bool,
};

export default withBreakpoint(MainPage);
