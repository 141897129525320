import PropTypes from 'prop-types';

import useRequest from 'core/hooks/useRequest';
import { rawRubricQuery, rawTopicsQuery } from 'core/queries/rubric';

import { filterRequiredParams } from 'core/utils/api';

import Rubric from 'core/components/Rubric';
import PageLoader from 'core/components/Loader/PageLoader';
import Page from 'core/components/Page';
import { withBreakpoint } from 'core/components/breakpoint';
import H1 from 'core/components/H1';
import H5 from 'core/components/H5';
import { PageIndent, Indent } from 'core/components/Wrappers';

import RubricTopics from 'site/components/RubricTopics';

import { CardMainSWithBg, CardMainMWithBg } from 'site/cards/CardMain';
import { CardVerticalM } from 'site/cards/CardVertical';

import { RUBRICS_PAGE_LIMIT } from 'site/constants';


function RubricPage({ isMobile, location, match, history }) {
  const [
    { data: rawRubric, isLoading: rubricIsLoading },
    { data: rawTopics, isLoading: topicsAreLoading },
  ] = useRequest({
    queries: [
      rawRubricQuery({ match, history }),
      rawTopicsQuery({
        location,
        match,
        history,
        limit: RUBRICS_PAGE_LIMIT,
        include: filterRequiredParams([CardVerticalM, CardMainMWithBg, CardMainSWithBg], 'include'),
        fields: filterRequiredParams([CardVerticalM, CardMainMWithBg, CardMainSWithBg], 'fields'),
      }),
    ],
  });

  return (
    <Page>
      <PageIndent top={isMobile ? 10 : 20}>
        <Rubric
          rawRubric={rawRubric}
          rawTopics={rawTopics}
          limit={RUBRICS_PAGE_LIMIT}
          titleComponent={({ children }) => (
            <H1>{children}/</H1>
          )}
          isLoading={topicsAreLoading || rubricIsLoading}
        >
          {({ topics }) => {
            if (topicsAreLoading) return <PageLoader />;
            if (!topics?.length) return <H5>Материалов по теме не найдено</H5>;

            return (
              <Indent bottom={isMobile ? 40 : 55} top={isMobile ? 0 : -30}>
                <RubricTopics topics={topics} />
              </Indent>
            );
          }}
        </Rubric>
      </PageIndent>
    </Page>
  );
}

RubricPage.propTypes = {
  isMobile: PropTypes.bool,
  location: PropTypes.object,
  match: PropTypes.object,
  history: PropTypes.object,
};

export default withBreakpoint(RubricPage);
