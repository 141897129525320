import PropTypes from 'prop-types';

import useRequest from 'core/hooks/useRequest';
import { tagQuery, topicsQuery } from 'core/queries/tagTopics';

import { filterRequiredParams } from 'core/utils/api';

import PageLoader from 'core/components/Loader/PageLoader';
import TagTopics from 'core/components/TagTopics';
import Page from 'core/components/Page';
import H5 from 'core/components/H5';

import { PageIndent } from 'core/components/Wrappers';
import RubricTopics from 'site/components/RubricTopics';

import { CardMainSWithBg, CardMainMWithBg } from 'site/cards/CardMain';
import { CardVerticalM } from 'site/cards/CardVertical';

import { RUBRICS_PAGE_LIMIT } from 'site/constants';


export default function TagPage({ location, match, history }) {
  const [
    { data: tag, isLoading: tagIsLoading },
    { data: topics, isLoading: tagTopicsIsLoading },
  ] = useRequest({
    queries: [
      tagQuery({ match, history }),
      topicsQuery({
        location,
        match,
        history,
        limit: RUBRICS_PAGE_LIMIT,
        include: filterRequiredParams([CardVerticalM, CardMainMWithBg, CardMainSWithBg], 'include'),
        fields: filterRequiredParams([CardVerticalM, CardMainMWithBg, CardMainSWithBg], 'fields'),
      }),
    ],
  });

  return (
    <Page>
      <PageIndent>
        <TagTopics
          tag={tag}
          topics={topics}
          limit={RUBRICS_PAGE_LIMIT}
          isLoading={tagIsLoading || tagTopicsIsLoading}
        >
          {props => {
            if (tagTopicsIsLoading) return <PageLoader />;
            if (!topics?.length) return <H5>Материалов по теме не найдено</H5>;

            return <RubricTopics {...props} />;
          }}
        </TagTopics>
      </PageIndent>
    </Page>
  );
}

TagPage.propTypes = {
  location: PropTypes.object,
  match: PropTypes.object,
  history: PropTypes.object,
};
