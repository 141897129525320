import PropTypes from 'prop-types';

import bindPropsHOC from 'core/components/bindProps';
import withTheme from 'core/components/theme';

import SmartImage from 'core/components/SmartImage';
import Link from 'core/components/Link';

import resolveRelationships from 'core/utils/relationships';
import modelPropTypes, { topicAttributes } from 'core/utils/prop-types/model';
import { topicDateFormat } from 'core/utils/dates';
import { resolveScopedStyles } from 'core/utils/styled-jsx';

import styles from './index.styl';

const requiredPayloadImports = [
  'image',
];

const requiredPayloadFields = [
  'link',
  'headline',
  'list_headline',
  'published_at',
  'alternative_headline',
];

const relationships = resolveRelationships(requiredPayloadImports, null, {
  image: {
    versions: {},
  },
});

const MAX_WIDTH = 500;


function CardMain(props) {
  const {
    content,
    size,
    theme,
    withBg,
  } = props;

  if (!content) return null;

  const {
    link,
    headline,
    published_at: publishedAt,
    list_headline: listHeadline,
    alternative_headline: altHeadline,
  } = content.attributes || {};

  const {
    image: {
      versions,
    },
  } = relationships(content) || {};

  const scoped = resolveScopedStyles(
    <scope>
      <style jsx>{`
        .${styles.link}
          &.${styles._withBg}
            &:after
              background-color ${theme.colors.flyByNight}
      `}</style>
    </scope>
  );

  return (
    <Link
      to={link}
      type='secondary'
      className={scoped.wrapClassNames(
        styles.link,
        styles[`_size_${size}`],
        withBg && styles._withBg
      )}
    >
      <style jsx>{`
        .${styles.infoBlock}
          font-family ${theme.fonts.text}
          color ${theme.colors.primary}

        .${styles.headline}
          font-family ${theme.fonts.display}
      `}</style>
      <div className={styles.wrapper}>
        <div className={styles.infoBlock}>
          {(listHeadline || headline) && (
            <div className={styles.headline}>
              {listHeadline || headline}
            </div>
          )}
          {altHeadline && (
            <div className={styles.altHeadline}>{altHeadline}</div>
          )}
          {publishedAt && (
            <div className={styles.time}>{topicDateFormat(publishedAt)}</div>
          )}
        </div>
        <div className={styles.imageWrapper}>
          <SmartImage
            versions={versions}
            maxWidth={MAX_WIDTH}
            aspectRatio={0.7}
          />
        </div>
      </div>
      <scoped.styles />
    </Link>
  );
}

CardMain.propTypes = {
  /** Данные карточки */
  content: modelPropTypes(topicAttributes),
  /** Размер карточки */
  size: PropTypes.oneOf(['s', 'm']),
  /** @ignore */
  theme: PropTypes.object,
  withBg: PropTypes.bool,
};

CardMain.defaultProps = {
  size: 'm',
};

const Card = withTheme(CardMain);

Card.requiredPayloadFields = requiredPayloadFields;
Card.requiredPayloadImports = requiredPayloadImports;

export { Card as CardMainM };
export const CardMainS = bindPropsHOC({ size: 's' })(Card);
export const CardMainMWithBg = bindPropsHOC({ withBg: true })(Card);
export const CardMainSWithBg = bindPropsHOC({ withBg: true, size: 's' })(Card);

export default Card;

export { CardMain as StorybookComponent };

