import { getEnv, resolve } from 'core/utils/env';

import { dark, darkMobile, light, lightMobile } from 'site/themes';

import { host, siteUrl } from 'site/constants';


/**
 * Этот конфиг вынесен в отдельный файл, чтобы шарить конфигурацию сайта
 * с галереей компонентов.
 * Сюда ни в коем случае не должно добавляться что-то кроме конфигурации
 * компонента App.
 */
export default function resolveConfig() {
  return {
    host: resolve({
      '*': host,
      'staging': getEnv('SITE_HOST'),
    }),
    theme: 'light',
    themes: {
      light: {
        base: light,
        mobile: lightMobile,
      },
      dark: {
        base: dark,
        mobile: darkMobile,
      },
    },

    coreApisConfig: {
      bebopApi: resolve({
        'dev': '//s1.festival2024.ru/api/v2',
      }),
    },

    webdavHost: resolve({
      '*': getEnv('WEBDAV_HOST'),
      'dev': '//wcdn.stage.unity.rambler.ru/fest2024',
    }),

    thumborHost: resolve({
      '*': getEnv('THUMBOR_HOST'),
      'dev': '//s1.thumbor.unity.rambler.tech/unsafe',
    }),

    erratumUrl: resolve({
      '*': `${siteUrl}/erratum`,
      'staging': `https://${getEnv('SITE_HOST')}/erratum`,
    }),

    ramblerPush: {
      projectUuid: resolve({
        '*': 'project::1ec534e4-bb9d-47d6-9d3e-243bc6955a20',
        'production': 'project::b37ba2d0-c4a2-458a-8469-a68a91df29b5',
      }),
      vapidKey: 'BNVJowQlKT8ha2fCVVoC_vPiZnC7MU-Z82p1XfBL_Pjc76IqoSQvfgHZNrd-uSsuCGLxrG7ca7brQXAJ6JHo7Do',
    },

    isUnityProject: true,

    schemaOrg: {
      organizationName: host,
      /**
       * Логотип должен быть прямоугольным и лежать в пределах 60x600px:
       * https://developers.google.com/search/docs/data-types/article#logo-guidelines
       */
      organizationLogoUrl: siteUrl + '/icons/publisher-logo.png',
      organizationLogoWidth: 181,
      organizationLogoHeight: 60,
    },

    defaultSiteMeta: {
      titleTemplate: `%s - ${host}`,
      defaultTitle: host,
    },

    counterIds: {
      yandexMetrika: 96229473,
    },

    features: {
      enableRetina: true,
      disableBlurPreview: true,
    },

    specialLinks: [
      '/exports/rss',
      '/about', // чтобы работала хеш навигация
    ],
    socials: [
      { name: 'telegram', link: 'https://t.me/rosmolodez' },
      { name: 'odnoklassniki', link: 'https://ok.ru/rosmolodez' },
      { name: 'vkontakte', link: 'https://vk.com/rosmolodez' },
    ],
  };
}
