import PropTypes from 'prop-types';
import cx from 'classnames';

import withTheme from 'core/components/theme';
import bindPropsHOC from 'core/components/bindProps';

import SmartImage from 'core/components/SmartImage';
import Link from 'core/components/Link';

import resolveRelationships from 'core/utils/relationships';
import modelPropTypes, { topicAttributes } from 'core/utils/prop-types/model';
import { topicDateFormat } from 'core/utils/dates';

import styles from './index.styl';

const requiredPayloadImports = [
  'image',
];

const requiredPayloadFields = [
  'link',
  'headline',
  'list_headline',
  'published_at',
];

const relationships = resolveRelationships(requiredPayloadImports, null, {
  image: {
    versions: {},
  },
});

const MAX_WIDTH = 240;


function CardReadMore({ content, theme, size }) {
  if (!content) return null;

  const {
    link,
    headline,
    list_headline: listHeadline,
    published_at: publishedAt,
  } = content.attributes;

  const {
    image: {
      versions,
    },
  } = relationships(content);

  return (
    <div className={cx(styles.wrapper, styles[`_size_${size}`])}>
      <style jsx>{`
        .${styles.wrapper}
          &.${styles._size_m}
            max-width ${MAX_WIDTH}px

        .${styles.headline}
          font-family ${theme.fonts.display}
          color ${theme.colors.primary}

        .${styles.time}
          font-family ${theme.fonts.text}
          color ${theme.colors.placeholder}
      `}</style>
      <Link
        to={link}
        type='secondary'
        className={styles.link}
      >
        <div className={styles.imageWrapper}>
          <SmartImage
            versions={versions}
            maxWidth={MAX_WIDTH}
            aspectRatio={1.5}
          />
        </div>
        <div className={styles.info}>
          {(listHeadline || headline) && (
            <div className={styles.headline}>
              {listHeadline || headline}
            </div>
          )}
          {publishedAt && (
            <div className={styles.time}>{topicDateFormat(publishedAt)}</div>
          )}
        </div>
      </Link>
    </div>
  );
}

CardReadMore.propTypes = {
  /** Данные карточки */
  content: modelPropTypes(topicAttributes),
  /** @ignore */
  theme: PropTypes.object,
  /** Размер карточки */
  size: PropTypes.string,
};

CardReadMore.defaultProps = {
  size: 'm',
};

const Card = withTheme(CardReadMore);

Card.requiredPayloadImports = requiredPayloadImports;
Card.requiredPayloadFields = requiredPayloadFields;

export { Card as CardReadMoreM };
export const CardReadMoreS = bindPropsHOC({ size: 's' })(Card);

export default Card;
