import PropTypes from 'prop-types';
import cx from 'classnames';

import useRequest from 'core/hooks/useRequest';
import { topicQuery } from 'core/queries/topic';

import { topicDateFormat } from 'core/utils/dates';
import resolveRelationships from 'core/utils/relationships';

import InfiniteTopic from 'core/components/InfiniteTopic';
import { withBreakpoint, Desktop, Mobile } from 'core/components/breakpoint';
import { PageIndent, Indent } from 'core/components/Wrappers';
import ColumnLayout from 'core/components/ColumnLayout';
import Socializator from 'core/components/Socializator';
import TopicHeaderTexts from 'core/components/TopicHeaderTexts';
import PageLoader from 'core/components/Loader/PageLoader';

import { SIDE_INDENT_MOBILE } from 'site/constants';

import TopicTags from './TopicTags';
import TopicCover from './TopicCover';
import TopicContent from './TopicContent';

import styles from './index.styl';

const relationships = resolveRelationships(['content'], {}, {
  content: {
    widgets: [],
  },
});

function topicRender(topic, enhancedTopic) {
  const { content, infinityIndex } = enhancedTopic;
  const { content: { widgets } } = relationships(content);

  const lead = content.attributes.announce;
  const hasReadMore = widgets.some(widget => widget.type === 'readMore');

  return (
    <div className={cx(styles.infinityTopic, styles[`_index_${infinityIndex}`])}>
      <Desktop>
        <ColumnLayout>
          <div className={styles.date}>{topicDateFormat(content.attributes.published_at)}</div>
          <TopicHeaderTexts topic={enhancedTopic} lead='' />
        </ColumnLayout>
        <ColumnLayout rightColumn={<TopicTags className={styles.topicTags} content={content} />}>
          {lead && <div className={styles.lead}>{lead}</div>}
          <Socializator />
          <Indent bottom={30} />
          <TopicCover content={content} />
          {!hasReadMore && <TopicContent content={content} />}
        </ColumnLayout>
        {hasReadMore && <TopicContent content={content} hasReadMore />}
      </Desktop>
      <Mobile>
        <div className={styles.header}>
          <div className={styles.date}>{topicDateFormat(content.attributes.published_at)}</div>
          <TopicHeaderTexts topic={enhancedTopic} lead='' />
        </div>
        {lead && <div className={styles.lead}>{lead}</div>}
        <Socializator />
        <TopicCover content={content} />
        <TopicContent content={content} />
        <TopicTags content={content} />
      </Mobile>
    </div>
  );
}

function TopicPage({ isMobile, location, match, history }) {
  const { data: content, isLoading: topicIsLoading } = useRequest(topicQuery({
    location,
    match,
    history,
  }));

  if (topicIsLoading) return <PageLoader />;

  return (
    <PageIndent
      top={isMobile ? 10 : 50}
      bottom={50}
      left={isMobile ? SIDE_INDENT_MOBILE : 118}
      right={isMobile ? SIDE_INDENT_MOBILE : 118}
    >
      <style jsx>{`
        /**
          Хак для того, чтобы залипающее в углу экрана видео, было над фигурами в подвале.
         */
        :global(.layoutContent)
          z-index auto
      `}</style>
      <InfiniteTopic content={content}>
        {topicRender}
      </InfiniteTopic>
    </PageIndent>
  );
}

TopicPage.propTypes = {
  isMobile: PropTypes.bool,
  location: PropTypes.object,
  match: PropTypes.object,
  history: PropTypes.object,
};

export default withBreakpoint(TopicPage);
